<template>
  <div class="types-setting">
    <div class="header">
      <div class="titre">
        <h4>Gestion des Types des projets</h4>
      </div>
      <div class="button">
        <b-button
          size="sm"
          variant="light"
          v-if="sortDesc"
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-up" />
        </b-button>
        <b-button
          size="sm"
          variant="light"
          v-else
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-down" />
        </b-button>
        <b-button
          size="sm"
          variant="success"
          v-b-modal.addProjetsTypesModal
          class="searchIcon"
        >
          <font-awesome-icon class="" icon="plus" />
          Ajouter un Type de projet
        </b-button>
      </div>
    </div>
    <div
      v-if="!initLoading && getProjectsTypesLoading"
      class="init-loading three-dots-loading"
    >
      Chargement en cours
    </div>
    <hr />
    <div v-if="initLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <b-list-group v-else>
      <b-list-group-item
        v-for="type in getProjectsTypes"
        href="#"
        :class="type.visible ? '' : 'collapsed'"
        :aria-controls="'collapse-' + type.id"
        @click="type.visible = !type.visible"
        :key="type.id"
        :value="type.id"
      >
        <div class="content">
          <div class="gras">{{ type.name }}</div>
          <div class="right">
            <div class="actions">
              <b-button
                size="sm"
                variant="primary"
                title="Gestion de sous-types"
                @click.prevent.stop="handleUpdateClick(type)"
              >
                <font-awesome-icon class icon="pencil-alt" />
              </b-button>
              <b-button
                size="sm"
                title="Supprimer"
                variant="danger"
                @click.prevent.stop="handleDeleteClick(type)"
              >
                <font-awesome-icon class icon="trash-alt" />
              </b-button>
            </div>
            <div class="icon">
              <font-awesome-icon class="icon1" icon="angle-up" />
              <font-awesome-icon class="icon1" icon="angle-down" />
            </div>
          </div>
        </div>
        <b-collapse
          :id="'collapse-' + type.id"
          v-model="type.visible"
          class="mt-2"
        >
          <b-card>
            <b-row>
              <b-col>
                <div>
                  <strong>Champs personnalisés</strong>
                  <ul
                    v-if="!type.fields || !type.fields.length"
                    class="aucunChamp"
                  >
                    Aucun champ personnalisé
                  </ul>
                  <ul v-else>
                    <li v-for="item in type.fields" :key="item.id">
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
      </b-list-group-item>
      <b-list-group-item
        class="aucun-type-peojet"
        v-if="(!getProjectsTypes || !getProjectsTypes.length) && !initLoading"
        >Aucun Type de projet</b-list-group-item
      >
    </b-list-group>
    <!-- Add Type_Projet Modal -->
    <b-modal
      no-close-on-backdrop
      id="addProjetsTypesModal"
      ref="addProjetsTypesModal"
      title="Ajouter un Type de projet"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form @submit.prevent="addProject">
        <b-form-group label="Nom" label-for="Nom-input">
          <b-form-input
            id="Nom-input"
            v-model="typeToAdd.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Dupliquer d'un autre type de projet ">
          <multiselect
            v-model="typeToAdd.selectedTypeProjet"
            :options="getListTypeProjetPrincipal || []"
            placeholder="Rechercher type de projet"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            label="name"
            track-by="id"
            :preselect-first="false"
            :loading="getProjectsTypesLoading"
          >
            <span slot="noResult">Aucun type de projet trouvé.</span>
            <span slot="noOptions">Aucun type de projet trouvé.</span>
          </multiselect>
        </b-form-group>
        <b-form-group label="Champs personnalisés">
          <b-form-checkbox-group
            v-model="typeToAdd.selected"
            :options="getCustomFieldProject"
            value-field="id"
            text-field="name"
            switches
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
        <!-- Add Type_Projet Modal: messages -->
        <div class="message">
          <div v-if="getProjectsTypesLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="getProjectsTypesError" class="error">
            <ul v-if="Array.isArray(getProjectsTypesError)">
              <li v-for="(e, index) in getProjectsTypesError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getProjectsTypesError }}</span>
          </div>
        </div>
        <!-- Add Type_Projet Modal: buttons -->
        <div class="form-actions">
          <b-button
            @click="hideModal('addProjetsTypesModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- END Add Type_Projet Modal -->

    <!-- Delete Type_Projet Modal -->
    <b-modal
      no-close-on-backdrop
      ref="ModalDelete"
      id="ModalDelete"
      title=" Supprimer un Type de projet"
      :hide-footer="true"
    >
      <p>
        Êtes-vous certain de vouloir supprimer le type
        <strong class="gras">
          {{ typeToDelete ? typeToDelete.name : '' }}</strong
        >
        ?
      </p>
      <div class="form-actions">
        <b-button @click="hideModal('ModalDelete')" variant="outline-secondary">
          Annuler
        </b-button>
        <b-button @click="deleteProject" variant="success">
          Valider
        </b-button>
      </div>
      <div class="message">
        <div v-if="getProjectsTypesLoading" class="three-dots-loading">
          Chargement en cours
        </div>
        <div v-if="getProjectsTypesError" class="error">
          <ul v-if="Array.isArray(getProjectsTypesError)">
            <li v-for="(e, index) in getProjectsTypesError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsTypesError }}</span>
        </div>
      </div>
    </b-modal>
    <!-- END Delete Type_Projet Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      typeToAdd: {
        name: null,
        selected: [],
        selectedTypeProjet: null
      },
      typeToUpdate: null,
      typeToDelete: null,
      initLoading: true,
      full_name: null,
      sortDesc: false,
      sort_by: 'full_name',
      sort_by_desc: 'full_name'
    }
  },
  methods: {
    ...mapActions([
      'fetchProjetsTypes',
      'fetchCustomFieldProject',
      'addProjectType',
      'updateProjectType',
      'deleteProjectType',
      'resetErrorProjectType',
      'fetchProjectTypePrincipal'
    ]),
    async filter() {
      if (!this.sortDesc) {
        this.fetchProjetsTypes({
          sort_by: this.sort_by,
          full_name: this.full_name
        })
      } else {
        this.fetchProjetsTypes({
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name
        })
      }
    },
    sortBy() {
      this.sortDesc = !this.sortDesc
      if (!this.sortDesc) {
        this.fetchProjetsTypes({
          sort_by: this.sort_by,
          full_name: this.full_name
        })
      } else {
        this.fetchProjetsTypes({
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name
        })
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },

    handleUpdateClick(type) {
      this.$router.replace('/setting/projects-types/' + type.id)
    },
    handleDeleteClick(type) {
      this.typeToDelete = { ...type }
      this.$refs['ModalDelete'].show()
    },
    resetModal() {
      this.typeToAdd = {
        name: null,
        selected: [],
        selectedTypeProjet: null
      }
      this.typeToUpdate = null
      this.typeToDelete = null
      this.resetErrorProjectType()
    },
    async deleteProject() {
      const response = await this.deleteProjectType(this.typeToDelete.id)
      if (response) {
        this.fetchProjectTypePrincipal()
        this.hideModal('ModalDelete')
      }
    },
    async addProject() {
      const response = await this.addProjectType(this.typeToAdd)
      if (response) {
        this.hideModal('addProjetsTypesModal')
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsTypes',
      'getCustomFieldProject',
      'getProjectsTypesLoading',
      'getProjectsTypesError',
      'getListTypeProjetPrincipal'
    ])
  },
  async mounted() {
    await this.fetchProjetsTypes({
      sort_by: this.sort_by,
      full_name: this.full_name
    })
    this.fetchCustomFieldProject()
    this.fetchProjectTypePrincipal()
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.types-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // white-space: nowrap;
    .button {
      display: flex;
      .searchIcon {
        border-radius: unset;
        box-shadow: unset;
        cursor: pointer;
        border: 1px solid #6c757d59;
        &:focus {
          box-shadow: unset;
        }
        &.padd-btn {
          padding: 0px 9px;
        }
      }
    }
  }
  .list-group {
    .aucun-type-peojet {
      color: #007bff;
      text-align: center;
    }
    .list-group-item {
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .gras {
          text-transform: capitalize;
          font-weight: bold;
        }
        .right {
          display: flex;
          justify-content: flex-end;

          .actions {
            display: block;
            margin-right: 20px;
            button {
              margin: 0 2px;
            }
          }
          .icon {
            display: flex;
            flex-direction: column-reverse;
            margin-right: 16px;
          }
        }
      }
      .mt-2 {
        ul {
          margin-top: 5px;
        }
      }

      &.collapsed {
        .icon {
          flex-direction: column !important;
        }
      }
    }
    .aucunChamp {
      color: #2196f3;
    }
  }
  @media screen and (max-width: 400px) {
    .header {
      white-space: unset;
    }
    .list-group {
      .aucun-type-peojet {
        color: #007bff;
        text-align: center;
      }
      .list-group-item {
        .content {
          display: block;

          .gras {
            text-transform: capitalize;
            font-weight: bold;
          }
          .right {
            display: flex;
            justify-content: flex-end;
            margin-top: 6px;

            .actions {
              display: block;
              margin-right: 20px;
              button {
                margin: 0 2px;
              }
            }
            .icon {
              display: flex;
              flex-direction: column-reverse;
              margin-right: 16px;
            }
          }
        }
        .aucunChamp {
          margin-top: 5px;
          margin-left: -31px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
#addProjetsTypesModal {
  .type {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 12px;
    color: #317ae2;
  }
  .icon-exclamation {
    text-decoration: line-through;
    text-decoration-color: #e73e01;
  }
  .custom-control-inline {
    display: block;
  }
}
#ModalDelete {
  .type {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 12px;
    color: #317ae2;
  }
  .icon-exclamation {
    text-decoration: line-through;
    text-decoration-color: #e73e01;
  }
  .custom-control-inline {
    display: block;
  }
  .gras {
    text-transform: capitalize;
    font-weight: bold;
  }
}
</style>
